import { writable } from "svelte/store";

const pageLoaded = writable(false);
const acceptedAge = writable(false);

const crawlerBot = /bot|googlebot|crawler|spider|robot|bot|crawling|lighthouse/i.test(navigator.userAgent);
const referrer = window.location.href.split('/')[2] === window.document.referrer.split('/')[2];

const setLoadedState = ()=>{
    pageLoaded.set(true);
}

if(
	document.readyState === 'complete'
){
    pageLoaded.set(true);
}else{
    window.addEventListener('load', ()=>{
        pageLoaded.set(true);
    });
}

export {pageLoaded, acceptedAge, crawlerBot, referrer};